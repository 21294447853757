import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-index-classic-saas',
  templateUrl: './index-classic-saas.component.html',
  styleUrls: ['./index-classic-saas.component.css']
})

/***
 * Classic Saas Component
 */
export class IndexClassicSaasComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  
  constructor(private modalService: NgbModal) { 
  }
  customOptions: OwlOptions = {
    // loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      900: {
        items: 1
      }
    },
    nav: false
  };

  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 2,
    decimalPlaces: 2,
  };

  /**
   * Member Data
   */
  managementMemberData = [
    {
      profile: "assets/images/client/elena.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Elena Alexandrova",
      designation: "CEO"
    },
    {
      profile: "assets/images/client/aranzazu.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Aranzazu Mateo",
      designation: "CFO"
    },
    {
      profile: "assets/images/client/amaia.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Amaia Úriz",
      designation: "HR"
    },
    {
      profile: "assets/images/client/raul.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Raúl Calavia",
      designation: "CMO & BDM"
    },
    {
      profile: "assets/images/client/fernando.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Fernando Sarría",
      designation: "Advisor"
    }
  ];

  /**
   * Member Data
   */
  techMemberData = [
    {
      profile: "assets/images/client/ander.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ander Cilveti",
      designation: "Team Lead & Software Developer"
    },
    {
      profile: "assets/images/client/gonzalo.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Gonzalo Berástegui",
      designation: "Software Developer"
    },
    {
      profile: "assets/images/client/daniel.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Daniel Huarte",
      designation: "Software Developer"
    },
    {
      profile: "assets/images/client/enrique.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Enrique Lafraya",
      designation: "Software Developer"
    },
    {
      profile: "assets/images/client/javier.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Javier Gracia",
      designation: "Software Developer"
    },
    {
      profile: "assets/images/client/jorge.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Jorge Canarias",
      designation: "Customer success specialist"
    },
  ];


  /**
   * Services Data
   */
  servicesData = [
    {
      icon: "uil uil-calendar-alt h2 text-primary",
      title: "Full lifecycle companion",
      description: "We will help you in the initial phase developping the ecosystem, as well as during the operation, providing support and maintenance for all the software."
    },
    {
      icon: "uil uil-clock h2 text-primary",
      title: "Cost effective",
      description: "Using a reusable base means you will avoid spending resources in already made software structures. Having our team providing maintenance to a common platform means your operational cost will be slashed by 70%."
    },
    {
      icon: "uil uil-upload-alt h2 text-primary",
      title: "Fast to market",
      description: "Jumpstart your journey from a ready to customize webapp, mobile app, database, backend and much more. Don't reinvent the wheel, Zero has the most used building blocks required for a complete and customizable software ecosystem for your IoTs. "
    },
    {
      icon: "uil uil-channel-add h2 text-primary",
      title: "Customizable to fit your requirements",
      description: "The architecture of our solution allows for building specific software upon our base platform easily, enabling you to maximize the usable value of your IoTs"
    },
    {
      icon: "uil uil-database h2 text-primary",
      title: "Own your data",
      description: "Our solution is deployable as you want it and where you want it, meaning that you could have it running in your own servers if you want, having control over the generated data."
    },
    {
      icon: "uil uil-lock-open-alt h2 text-primary",
      title: "No lock-in policy",
      description: "The possibility of having zero running on your servers, with a lifetime licensing model and ownership over the software developed for you means having the independence to decide over your future, without constraints due to lock-ins."
    }
  ];

  /**
   * Pricing Data
   */
  pricingData = [
    {
      title: "Basic",
      price: 100,
      list: [
      "50€/h rate for development hours",
      "Support by Ticket system", 
      "Support time on a best effort basis", 
      "10GB of data storage included + 0.12€/GB per month",
      "100 datapoints display in the same request",
      "Cloud version",
      "Web",
      "Partial whitelabel",
      "10 rule per user max",
      "Basic rules type included"],
      btn: "Request meeting"
    },
    {
      title: "Professional",
      price: 1000,
      list:  [
      "50€/h rate for development hours",
      "Support by Ticket system + Support by mail + Dedicated account manager", 
      "Support of 8x5",
      "Answer max time of 24h", 
      "Monthly follow up meeting",
      "100GB of data storage included + 0.12€/GB per month",
      "500 datapoints display in the same request",
      "Cloud version",
      "Web + App Mobile",
      "Full whitelabel.",
      "50 rule pero user max",
      "Advanced rules type included",
      "15 software development hours included."],
      btn: "Request meeting"
    },
    {
      title: "Enterprise Cloud",
      price: 4000,
      list:  [
      "50€/h rate for development hours",
      "Support by Ticket system + support by mail + support by phone + Dedicated account manager", 
      "Support of 24x7",
      "Instant answer", 
      "Monthly follow up meeting",
      "1000GB of data storage included + 0.12€/GB per month",
      "10000 datapoints display in the same request",
      "Cloud version",
      "Web + App Mobile",
      "Full whitelabel.",
      "500 rule pero user max",
      "Custom rules type included",
      "150h software development hours included"
      ],
      btn: "Request meeting"
    },
    {
      title: "Enterprise On Premise",
      price: 4000,
      list:  [
        "WebApp: available",
        "App: available",
        "Node quantity: unilimited", 
        "Database size: unlimited",
        "Rule / alert / condition  quantity: unlimited", 
        "SMS/mail/push notification: unilimited",
        "Sent/received measures: unlimited",
        "MQTT services: free",
        "LoRa services: free",
        "Log management services: free",
        "Cloud:available",
        "On premise server: available",
        "Full whitelabel."
      ],
      btn: "Request meeting"
    }
  ];

  /**
   * Pricing Data
   */
  pricingDataLicensing = [
    {
      title: "Licensing plan",
      price: "0€/month",
      list:  [
        "WebApp: available",
        "App: available",
        "Node quantity: unilimited", 
        "Database size: unlimited",
        "Rule / alert / condition  quantity: unlimited", 
        "SMS/mail/push notification: unilimited",
        "Sent/received measures: unlimited",
        "MQTT services: free",
        "LoRa services: free",
        "Log management services: free",
        "Cloud:available",
        "On premise server: available",
        "Full whitelabel (Optional): 1000€/month"
      ],
      btn: "Request meeting"
    },
    {
      title: "Support plan",
      price: "2000€/month",
      list: [
      "24x7 incidence support available",
      "Dedicated account manager", 
      "Mail and phone for communication", 
      "2h/week general support included",
      "2h/week proactive support included",
      "1h/week follow up and consulting meetings included",
      "10h/month development hours included",
      ],
      btn: "Request meeting"
    },
    {
      title: "Development rates",
      price: "60€/h",
      list: [
      "Rate for development hours",
      "Specific developments", 
      "Extended support cases", 
      "Infraestructure operations",
      ],
      btn: "Request meeting"
    }
  ];

  /**
   * Pricing Data
   */
  pricingDataWhiteLabel =[
    {
      title: "Full whitelabel (Optional)",
      price: "1000€/month",
      list: [
      "Full whitelabel on the whole platform for complete branding",
      ],
      btn: "Request meeting"
    }
  ];

  /**
   * Pricing Data
   */
  pricingDataSupport = [
    {
      title: "Support plan",
      price: "2000€/month",
      list: [
      "24x7 incidence support available",
      "Dedicated account manager", 
      "Mail and phone for communication", 
      "2h/week general support included",
      "2h/week proactive support included",
      "1h/week follow up and consulting meetings included",
      "10h/month development hours included",
      ],
      btn: "Request meeting"
    }
  ];


  /**
   * Pricing Data
   */
  pricingDataDevelopment = [
    {
      title: "Development rates",
      price: "60€/h",
      list: [
      "Rate for development hours",
      "Specific developments", 
      "Extended support cases", 
      "Infraestructure operations",
      ],
      btn: "Request meeting"
    }
  ];


  ngOnInit(): void {
  }

  /**
   * Open modal for show the video
   * @param content content of modal
   */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
