<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 overflow-hidden" id="home">
  <div class="container">
    <div class="row align-items-center pt-5">
      <div class="col-lg-9 col-md-6">
        <div class="title-heading">
          <h1 class="heading mb-3">La plataforma de software reutilizable <br> para los cimientos <br> de tu ecosistema de productos IoT</h1>
          <p class="para-desc text-muted">Concéntrate en construir tu excepcional hardware IoT, y <u><b>evita malgastar el 70%</b></u> de los recursos reinventando la rueda al desarrollar desde cero el ecosistema de software para tus dispositivos IoT.</p>
          <div class="mt-4 pt-2">
            <a href="mailto:contact@zeroplatform.io" routerLink="/index-classic-saas" class="btn btn-primary">Agendar
              <span class="badge rounded-pill bg-danger ms-2">llamada de 30 min</span></a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="classic-saas-image position-relative">
          <div class="bg-saas-shape position-relative">
            <img src="assets/images/saas/classic01.png" class="mx-auto d-block" alt="">
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="pt-5">
  <div class="container">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center"></app-clients-logo>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Section Start -->
<section class="section overflow-hidden">
  <div class="container pb-5 mb-md-5">
    <app-services [servicesData]="servicesData" service_class="features feature-primary text-center"></app-services>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container-fluid pt-4 mt-100 mt-60">
    <div class="row justify-content-center" id="counter">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Construir dispositivos IoT es difícil. <span class="text-primary">
              No lo hagas más difícil.</span></h4>
          <p class="text-muted para-desc mx-auto mb-0">Te entendemos, estás construyendo un dispositivo IoT excepcional,
            que es en lo que tú y tu organización realmente sois buenos. Pero sabes que hoy en día,
            un dispositivo aislado tiene menos posibilidades de éxito en comparación con aquellos que tienen un
            ecosistema de software para potenciarlo. Ahora, con Zero, puedes evitar los dolores de cabeza de establecer un nuevo equipo de desarrollo de software,
            un proceso largo y costoso de desarrollar todo el software desde cero,
            un requisito costoso de servicio de soporte y mantenimiento, y disfrutar de un ecosistema de software completo
            adaptado a tus dispositivos y requisitos con una fracción del tiempo de desarrollo, costo de desarrollo, costo de mantenimiento,
            y todo respaldado por un equipo técnico especializado</p>
          <div class="watch-video mt-4">
            <a href="mailto:contact@zeroplatform.io" class="btn btn-primary m-1 me-2">Quiero saber más <i
                class="mdi mdi-chevron-right"></i></a>
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0)" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Ver Ahora</span>
            <ng-template #content let-modal>
        
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'jNTZpfXYJa4'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="saas-feature-shape-left position-relative">
          <img src="assets/images/saas/classic02.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ms-lg-4">
          <h1 class="title mb-3">¿De qué somos capaces?</h1>
          <p class="para-desc text-muted">Obtén una idea de lo que somos capaces utilizando Zero como plataforma para construir soluciones IoT completas.</p>
      
          <owl-carousel-o id="customer-testi" [options]="customOptions">
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <img src="assets/images/client/01.jpg" class="img-fluid avatar avatar-small rounded-circle shadow" alt="">
                <p class="text-muted mt-4">DENODL es un ecosistema IoT para un control y monitorización inteligentes de los recursos hídricos de entornos urbanos, agrícolas y domésticos.</p>
                <h6 class="text-primary">- DENODL</h6>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <img src="assets/images/client/02.jpg" class="img-fluid avatar avatar-small rounded-circle shadow" alt="">
                <p class="text-muted mt-4">PlainLogger es una solución de gestión de registros centrada en la simplicidad y facilidad de uso, dirigida a equipos pequeños y desarrolladores independientes.</p>
                <h6 class="text-primary">- PlainLogger</h6>
              </div>
            </ng-template>
          </owl-carousel-o>
          <div class="col-12 pt-4">
            <a href="mailto:contact@zeroplatform.io" class="btn btn-outline-primary">Descubre más <i class="uil uil-angle-right-b"></i></a>
          </div>
        </div>
      </div>
      
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title me-lg-4">
          <h1 class="title mb-3">¿Cómo es el aspecto tecnológico de Zero como plataforma?</h1>
<p class="para-desc text-muted">La plataforma en la que basamos nuestro trabajo, Zero, está construida pensando en la reutilización y reasignación de propósitos. 
  Con ella, podemos finalizar nuevos proyectos IoT en tiempo récord a una fracción del costo.</p>

          <div class="row">
            
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <h2>
                  <i-feather name="cpu" class="fea icon-m-md text-primary"></i-feather>
                </h2>
                <div class="ms-3">
                  <h5>.NET backend</h5>
                  <p class="text-muted mb-0"></p>
                  </div>
                </div>
              </div>
              <!--end col-->
              
              <div class="col-12">
                <div class="d-flex align-items-center pt-4">
                  <h2>
                    <i-feather name="cpu" class="fea icon-m-md text-primary"></i-feather>
                  </h2>
                  <div class="ms-3">
                    <h5>Angular frontend Web</h5>
                    <p class="text-muted mb-0">I</p>
                    </div>
                  </div>
                </div>
                <!--end col-->
                
                <div class="col-12">
                  <div class="d-flex align-items-center pt-4">
                    <h2>
                      <i-feather name="cpu" class="fea icon-m-md text-primary"></i-feather>
                    </h2>
                    <div class="ms-3">
                      <h5>Frontend móvil Xamarin</h5>
                      <p class="text-muted mb-0"></p>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-12">
                    <div class="d-flex align-items-center pt-4">
                      <h2>
                        <i-feather name="shield" class="fea icon-m-md text-primary"></i-feather>
                      </h2>
                      <div class="ms-3">
                        <h5>Arquitectura de plugins</h5>
                        <p class="text-muted mb-0"></p>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

            <div class="col-12 pt-4">
              <a href="mailto:contact@zeroplatform.io" class="btn btn-outline-primary">Descubre más <i
                  class="uil uil-angle-right-b"></i></a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img src="assets/images/saas/classic02.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Nuestras Tarifas de Referencia</h4>
            <p class="text-muted para-desc mb-0 mx-auto">Trabajamos caso por caso, adaptando cada caso a los requisitos. Sin embargo, podemos dar los siguientes valores de referencia. Para obtener una cotización final, puedes contactarnos.
  </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-pricing [pricingData]="pricingData"></app-pricing>
  </div>
  <!--end container-->

   <!-- Team Start -->
   <section class="section bg-light">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Nuestro equipo gestor</h4>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <app-member [memberData]="managementMemberData"></app-member>
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Team End -->

   <!-- Team Start -->
   <section class="section bg-light">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Nuestro equipo técnico.</h4>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <app-member [memberData]="techMemberData"></app-member>
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Team End -->

  <!-- Team Start -->
  <section class="section bg-light">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">FAQ</h4>
            <p class="text-muted para-desc mx-auto mb-0" data-aos="fade-up" data-aos-duration="1400">Respuestas a preguntas comunes.
            </p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-8">
            <div class="faq-content me-lg-5">
    
              <div ngbAccordion activeIds="div ngbAccordionItem-0" closeOthers="true">
                <div ngbAccordionItem cardClass="border-0 rounded shadow mb-2">
                  <h2 ngbAccordionHeader>
                    <div class="border-0 bg-light" id="headingtwo">
                      <button ngbAccordionButton class="title mb-0"> Digamos que estoy algo interesado. ¿Puedes describir el proceso típico de trabajo con ustedes? </button>
                    </div>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                    <ng-template class="text-muted mb-0 faq-ans"> Si estás interesado, primero programaremos una llamada rápida para explicarte todo mejor, y podrás hacer tus principales preguntas o preocupaciones. Si piensas que podríamos ser un buen socio, explicarás lo que tienes en mente para tu ecosistema IoT, y a partir de
                      ahí te daremos retroalimentación basada en nuestra experiencia, y desde ahí, podemos hacer una cotización dependiendo de lo que necesites. Si ambos estamos de acuerdo, comenzaremos a desarrollar basándonos en la plataforma Zero el software adaptado a tus requerimientos. En el proceso, tendremos reuniones periódicas para que puedas dar retroalimentación
                      sobre el trabajo realizado y el avance. Una vez terminado el desarrollo, entramos en la parte de soporte y mantenimiento del software, donde dependiendo de los requerimientos acordados proporcionaremos diferentes niveles de atención.
                    </ng-template>
                    </div>
                  </div>
                </div>
            
                <div ngbAccordionItem cardClass="border-0 rounded shadow mb-2">
                  <h2 ngbAccordionHeader>
                    <div class="border-0 bg-light" id="headingtwo">
                      <button ngbAccordionButton class="title mb-0"> Me preocupa la dependencia que mi negocio tendría de ustedes. ¿Qué pasa si no me gusta su forma de trabajar en el futuro o si quiebran? </button>
                    </div>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                    <ng-template class="text-muted mb-0 faq-ans">Primero, tenemos un alto grado de confianza en que querrás trabajar con nosotros para siempre, pero en caso de que no sea así, puedes ejecutarlo por tu cuenta con una licencia vitalicia, con el código base disponible para que puedas mantenerlo y desarrollarlo por tu cuenta.</ng-template>
                    </div>
                  </div>
                </div>
    
                <div ngbAccordionItem cardClass="border-0 rounded shadow mb-2">
                  <h2 ngbAccordionHeader>
                    <div class="border-0 bg-light" id="headingtwo">
                      <button ngbAccordionButton class="title mb-0"> ¿Necesitaré pagarles cada vez que quiera un nuevo desarrollo para mi ecosistema de software? ¿Puedo tener mis propios desarrolladores haciendo nuevo software? </button>
                    </div>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                    <ng-template class="text-muted mb-0 faq-ans">Creemos que nuestra calidad/precio de desarrollo no será fácil de superar, pero si por alguna razón quieres hacerlo, sí, puedes desarrollar por tu cuenta e integrarlo en el ecosistema dada la arquitectura de plugins que tenemos.</ng-template>
                    </div>
                  </div>
                </div>
            
                <div ngbAccordionItem cardClass="border-0 rounded shadow mb-2">
                  <h2 ngbAccordionHeader>
                    <div class="border-0 bg-light" id="headingtwo">
                      <button ngbAccordionButton class="title mb-0"> ¿Por qué es tan rentable?
                      </button>
                    </div>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                    <ng-template class="text-muted mb-0 faq-ans">En el desarrollo, la mayoría de los ecosistemas IoT tienen algunas características y requerimientos comunes. Ya hemos hecho eso en nuestro código, así que ahorramos todo ese tiempo y costo de desarrollo.
                      cuando proporcionamos soporte y mantenimiento, tenemos el beneficio de la escala que un negocio individual no tendría. Cuando monitorizamos la salud de nuestra plataforma, por ejemplo, estamos monitorizando la salud de múltiples proyectos al mismo tiempo, por lo que podemos compartir el costo.
                    </ng-template>
                    </div>
                  </div>
                </div>
    
              </div>
    
            </div>

          </div>
          <!--end col-->
          
          <div class="col-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <img src="assets/images/illustrator/faq.svg" alt="">
          </div>
          <!--end col-->
          </div>
          <!--end row-->
          </div>
          <!--end container-->
          </div>
          <!--end container-->
          </section>
          <!--end section-->
          <!-- Team End -->
          
          






  

<div class="container mt-100 mt-60">
  <div class="rounded bg-primary bg-gradient p-lg-5 p-4">
    <div class="row align-items-end">
      <div class="col-md-8">
        <div class="section-title text-md-start text-center">
          <h4 class="title mb-3 text-white title-dark">Programemos una llamada de 30 minutos para saber mejor cómo podríamos ayudar</h4>
          <p class="text-white-50 mb-0">Nos encantaría escuchar sobre tu idea.
        </div>
      </div>
      <!--end col-->
  
      <div class="col-md-4 mt-4 mt-sm-0">
        <div class="text-md-end text-center">
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Section End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->