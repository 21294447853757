<div class="row"

>
        @for(data of clients_logo;track $index){
        <div class="{{brand_class}}"
        style="
        margin-left: 5%;
        margin-top: 10%;
        margin-right: 5%;
        width: 250px;
        position:relative;
        align-content: center;
        align-items: center;
        ">
        <img src="{{data.image}}" alt=""
        style="
        width: 250px;
        height: fit-content;
        align-content:center;
        position: relative;
        ">
    </div>
}
</div>