import { Component, OnInit, Input } from '@angular/core';

interface client {
  image: string;
};

@Component({
  selector: 'app-clients-logo',
  templateUrl: './clients-logo.component.html',
  styleUrls: ['./clients-logo.component.css']
})
export class ClientsLogoComponent implements OnInit {

  @Input() brand_class: string | undefined;
  /**
   * Clients Logo
   */
   clients_logo: client[] = [
    {
      image: "assets/images/client/denodl-logo-gris.svg"
    },
    {
      image: "assets/images/client/fsgroup-gris.svg"
    },
    {
      image: "assets/images/client/pl-byzero-2024-01-15.svg"
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
