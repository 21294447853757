import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-not-found',
  template: `
  `,
})
export class NotFoundComponent implements OnInit {

  private apiUrl = 'https://z.denodl.com/bk/general/route'; // API endpoint

  constructor(private router: Router, private http: HttpClient) {}

  /**
   * Send the tracked route to the backend for commercial reasons.
   * @param route Tracked URL route
   * @returns Observable<boolean> indicating success or failure
   */
  postRoute(route: string): Observable<boolean> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // Constructing the payload to match backend's PostRouteRequest
    const postRouteRequest = {
      route: route
    };

    return this.http.post(this.apiUrl, postRouteRequest, { headers }).pipe(
      map(() => true),  // Map to `true` on success
      catchError((error) => {
        console.error(`Error during route tracking: ${error.status} - ${error.message}`);
        return throwError(() => new Error('Failed to send route tracking request'));
      })
    );
  }

  ngOnInit(): void {
    // Log the original URL (the URL the user tried to access)
    const attemptedUrl = this.router.url;
    console.log('Attempted to access:', attemptedUrl);

    // Send the tracked URL to the backend
    this.postRoute(attemptedUrl).subscribe({
      next: (success) => {
        if (success) {
          console.log('Route tracked successfully.');
          // After success, redirect to the home page
          this.redirectToHome();
        }
      },
      error: (err) => {
        console.error('Route tracking failed:', err);
        // Even if the tracking fails, we still redirect
        this.redirectToHome();
      }
    });
  }

  // Function to redirect the user to the home page after a delay
  redirectToHome(): void {
    setTimeout(() => {
      this.router.navigate(['/']); // Redirect to the home page
    }, 0);  // 2 seconds delay before redirect
  }
}
