
<!-- Hero Start -->
<section class="section overflow-hidden" id="home">
  <div class="container">
    <img src="assets/images/saas/zeroLogo_24abr2024.svg"   class="img-fluid d-block rounded " style="align-content: flex-start;" width="750">
    <div class="row align-items-center pt-5">
      <div class="col-lg-9 col-md-6">
        <div class="title-heading">
          <h1 class="heading mb-3">The reusable software platform <br> for the foundation of<br> your IoT product ecosystem</h1>
          <p class="para-desc text-muted">Focus on building your exceptional IoT hardware, <br> <u><b>avoid wasting 70%</b></u> of the resources reinventing the wheel when developing from scratch the software ecosystem for your IoT devices.</p>
          <div class="mt-4 pt-2">
            <a href="https://calendly.com/acilveti-zeroplatform/demo-zero" class="btn btn-primary">Schedule 
              <span class="badge rounded-pill bg-danger ms-2">30 min</span> call</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="classic-saas-image position-relative">
          <div class="bg-saas-shape position-relative" style="">
            <img src="assets/images/saas/mac1.png" alt="" style="width: 1000px;">
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="section pt-5 overflow-hidden">
  <div class="container">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center"></app-clients-logo>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Section Start -->
<section class="section pt-5 overflow-hidden">
  <div class="container pb-5 mb-md-5"
  style="
  margin-top: 75px
  ">
    <app-services [servicesData]="servicesData" service_class="features feature-primary text-center"></app-services>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container-fluid pt-4 mt-100 mt-60">
    <div class="row justify-content-center" id="counter">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Building IoT devices is hard. <span class="text-primary">
              Don't make it harder.</span></h4>
          <p class="text-muted para-desc mx-auto mb-0">We got you, you are building an exceptional IoT device, 
            which is what you and your organization really are good at. But you know that nowdays, 
            a standalone device has smaller chance of being succesfull comparing to those with a 
             software ecosystem to empower it. Now, with Zero, you can avoid the headaches of setting up a new software development team, 
             a long and expensive process of developing all the software from scratch, 
             a costly support and maintenance service requirement, and enjoy a full software ecosystem
             tailored to your devices and requirements at a fraction of the development time, development cost, maintenance cost, 
             and everything backed by a specialized technical team</p>
          <div class="watch-video mt-4">
            <a href="mailto:contact@zeroplatform.io" class="btn btn-primary m-1 me-2">I want to know more <i
                class="mdi mdi-chevron-right"></i></a>
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0)" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'jNTZpfXYJa4'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="saas-feature-shape-left position-relative">
          <img src="assets/images/saas/denodl-screenshot.PNG" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ms-lg-4">
          <h1 class="title mb-3">What are we capable of?</h1>
          <p class="para-desc text-muted">Get a feeling of what we are capable of using Zero as a platform to build entire IoT solutions.</p>

          <owl-carousel-o id="customer-testi" [options]="customOptions">
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <!--<img src="" class="img-fluid avatar avatar-small rounded-circle shadow" alt="">-->
                <p class="text-muted mt-4">DENODL is an IoT ecosystem for a smart control and monitorization of the hydric resources of urban, agricultural and domestic environments. </p>
                <h6 class="text-primary">- DENODL</h6>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <!--<img src="" class="img-fluid avatar avatar-small rounded-circle shadow" alt=""-->
                  <p class="text-muted mt-4">PlainLogger is a log management solution focused on simplicity and ease of use,  aimed to small teams and solo-devs. </p>
                  <h6 class="text-primary">- PlainLogger</h6>
              </div>
            </ng-template>
          </owl-carousel-o>
          <div class="col-12 pt-4">
            <a href="mailto:contact@zeroplatform.io" class="btn btn-outline-primary">Discover more <i
                class="uil uil-angle-right-b"></i></a>
          </div>
        </div>
      </div>
      
      <!--end col-->
    </div>
    <!--end row-->
  </div>

  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title me-lg-4">
          <h1 class="title mb-3">How is the technical side of Zero as a platform ?</h1>
          <p class="para-desc text-muted">The platform we base our work on, Zero, is built with being reusable and repurpousable in mind. 
            Thanks to Zero, we can finish new IoT projects in record time at a fraction of the cost.</p>
          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <h2>
                  <i-feather>
                    <img
                     src="assets/images/saas/netLogo.svg"
                     style="
                     height: 25px;
                     border-radius: 0px;
                     margin-bottom: 8px;"
                     alt="">
                  </i-feather>
                </h2>
                <div class="ms-3">
                  <h5>.NET backend</h5>
                  <p class="text-muted mb-0"><!--If the fill text is intended to illustrate the characteristics of
                    sometimes.--></p>
                  </div>
                </div>
              </div>
              <!--end col-->
              
              <div class="col-12">
                <div class="d-flex align-items-center pt-4">
                  <h2>
                    <i-feather>
                      <img
                      src="assets/images/saas/Angular_logo.svg"
                      style="
                      height: 25px;
                      border-radius: 0px;
                      margin-bottom: 8px;
                      ">
                    </i-feather>
                  </h2>
                  <div class="ms-3">
                    <h5>Angular Web frontend</h5>
                    <p class="text-muted mb-0"><!--If the fill text is intended to illustrate the characteristics of
                      sometimes.--></p>
                    </div>
                  </div>
                </div>
                <!--end col-->
                
                <div class="col-12">
                  <div class="d-flex align-items-center pt-4">
                    <h2>
                      <i-feather>
                        <img
                        src="assets/images/saas/xamarinLogo.svg"
                        style="
                        height: 25px;
                        border-radius: 0px;
                        margin-bottom: 8px;
                        ">
                      </i-feather>
                    </h2>
                    <div class="ms-3">
                      <h5>Xamarin Mobile frontend</h5>
                      <p class="text-muted mb-0"><!--If the fill text is intended to illustrate the characteristics of
                        sometimes.--></p>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-12">
                    <div class="d-flex align-items-center pt-4">
                      <h2>
                        <i-feather>
                          <img
                          src="assets/images/saas/puzzleIcon.svg"
                          style="
                          height: 25px;
                          border-radius: 0px;
                          margin-bottom: 8px;
                          ">
                        </i-feather>
                      </h2>
                      <div class="ms-3">
                        <h5>Plugin architecture</h5>
                        <p class="text-muted mb-0"><!--If the fill text is intended to illustrate the characteristics of
                          sometimes.--></p>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

            <div class="col-12 pt-4">
              <a href="mailto:contact@zeroplatform.io" class="btn btn-outline-primary">Discover more <i
                  class="uil uil-angle-right-b"></i></a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img src="assets/images/saas/denodlMobile.png" class="img-phone-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Reference Rates</h4>
          <p class="text-muted para-desc mb-0 mx-auto">We work on a case by case basis, fitting each case to its requirements. However, we can give the following values for reference. To get a final quotation you can contact us.
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-pricing [pricingData]="pricingDataLicensing"></app-pricing>
  </div>
  <!--end container-->

   <!-- Team Start -->
   <section class="section bg-light"
   style="margin-top: 40px; padding-top: 50px; padding-bottom: 50px;">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Our Management Team</h4>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <app-member [memberData]="managementMemberData"></app-member>
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Team End -->

   <!-- Team Start -->
   <section class="section bg-light"
   style="padding-top: 75px;">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Our Tech Team</h4>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <app-member [memberData]="techMemberData"></app-member>
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Team End -->

  <!-- Team Start -->
  <section class="section bg-light">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">FAQ</h4>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="faq-content me-lg-5">
    
              <div ngbAccordion activeIds="div ngbAccordionItem-0" closeOthers="true">
                <div ngbAccordionItem cardClass="border-0 rounded shadow mb-2">
                  <h2 ngbAccordionHeader>
                    <div class="border-0 bg-light" id="headingtwo">
                      <button ngbAccordionButton class="title mb-0"> Let´s say I am somehow interested. Can you describe how the process of working with you will be? </button>
                    </div>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                    <ng-template class="text-muted mb-0 faq-ans">
                      - If you are interested, first we will schedule a quick call so we can explain everything better, and you can ask your main questions or concerns. <br>
                      - If you think we could be a good partner for you, you can tell us what you have in mind for your IoT ecosystem. We will give you feedback based on our experience. <br>
                      - Then, we can give you a quotation depending of what you need. <br>
                      - If we agree, we will start the development the software tailored to your requirements based on the Zero platform. <br>
                      - During the process, we will have periodic meetings so you can give us feedback on the work done and the new developments. <br>
                      - Once the development is done, we enter the support and maintentance stage, where we will give you the services previously agreed.
                    </ng-template>
                    </div>
                  </div>
                </div>
    
                <div ngbAccordionItem cardClass="border-0 rounded shadow mb-2">
                  <h2 ngbAccordionHeader>
                    <div class="border-0 bg-light" id="headingtwo">
                      <button ngbAccordionButton class="title mb-0 "> I am worried that my business could be too dependent on you. What if in the future I don't like the way you work, or if you go bust? </button>
                    </div>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                    <ng-template class="text-muted mb-0 faq-ans">First, we have to say that we have a high degree of confidence that you will want to work with us forever, but given the opposite case, you could run the platform on your own with a lifetime license, with the base code available so you can mantain and develop on your own.</ng-template>
                  </div>
                  </div>
                </div>
    
                <div ngbAccordionItem cardClass="border-0 rounded shadow mb-2">
                  <h2 ngbAccordionHeader>
                    <div class="border-0 bg-light" id="headingtwo">
                      <button ngbAccordionButton class="title mb-0"> Can I have my own developers doing new software? Will I need to pay you every time I want a new development done for my software ecosystem? </button>
                    </div>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                    <ng-template class="text-muted mb-0 faq-ans">We think that our development quality/price will not be easy to beat, but if you wanted, of course you can develop anything on your own and integrate it in the ecosystem using our plugin architecture.</ng-template>
                    </div>
                  </div>
                </div>
    
                <div ngbAccordionItem cardClass="border-0 rounded shadow mb-2">
                  <h2 ngbAccordionHeader>
                    <div class="border-0 bg-light" id="headingtwo">
                      <button ngbAccordionButton class="title mb-0"> Why is your solution so cost effective ?
                      </button>
                    </div>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                    <ng-template class="text-muted mb-0 faq-ans">At the development level, most IoT ecosystems have some common features and requeriments.
                      We have already implemented them in our platform, so we can avoid starting from the ground up every time.
                      When we provide support and maintentance, we have the benefit of scale that a single business will not have.
                      When we monitorize the health of our platform, for example, we are monitorizing the health of multiple projects at the same time, so we can spread the costs. 
                    </ng-template>
                    </div>
                  </div>
                </div>
    
              </div>
    
            </div>
          </div>
          <!--end col-->
    
          <div class="col-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <!-- <img src="assets/images/illustrator/faq.svg" alt=""> -->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Team End -->






  <div class="container mt-100 mt-60">
    <div class="rounded bg-primary bg-gradient p-lg-5 p-4">
      <div class="row align-items-end">
        <div class="col-md-8">
          <div class="section-title text-md-start text-center">
            <h4 class="title mb-3 text-white title-dark">Let´s schedule a 30 min call so we can know better how we could help</h4>
            <p class="text-white-50 mb-0">We would love to hear about your idea.
          </div>
        </div>
        <!--end col-->

        <div class="col-md-4 mt-4 mt-sm-0">
          <div class="text-md-end text-center">
            <a href="https://calendly.com/acilveti-zeroplatform/demo-zero" class="btn btn-light">Schedule a 30 min call</a>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
  <!--end container-->
  <div class="mt-4 pt-2 row align-items-center">
    <a href="mailto:contact@zeroplatform.io" class="btn btn-primary">Contact us  
      <span class="badge rounded-pill bg-danger ms-2">contact&#64;zeroplatform.io</span></a>
  </div>
</section>
<!--end section-->
<!-- Section End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->