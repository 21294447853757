import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-index-classic-saas-spanish',
  templateUrl: './index-classic-saas-spanish.component.html',
  styleUrls: ['./index-classic-saas-spanish.component.css']
})

/***
 * Classic Saas Component
 */
export class IndexClassicSaasSpanishComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  
  constructor(private modalService: NgbModal) { 
  }
  customOptions: OwlOptions = {
    // loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      900: {
        items: 1
      }
    },
    nav: false
  };

  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 2,
    decimalPlaces: 2,
  };

  /**
   * Member Data
   */
  managementMemberData = [
    {
      profile: "assets/images/client/elena.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Elena Alexandrova",
      designation: "CEO"
    },
    {
      profile: "assets/images/client/aranzazu.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "María Aranzazu Mateo Goenaga ",
      designation: "CFO"
    },
    {
      profile: "assets/images/client/amaia.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Amaia Úriz Zarza",
      designation: "HR"
    },
    {
      profile: "assets/images/client/raul.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Raúl Calavia Rodríguez",
      designation: "CMO & BDM"
    },
    {
      profile: "assets/images/client/fernando.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Fernando Sarría",
      designation: "Advisor"
    }
  ];

  /**
   * Member Data
   */
  techMemberData = [
    {
      profile: "assets/images/client/ander.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ander Cilveti",
      designation: "Team Lead & Software Developer"
    },
    {
      profile: "assets/images/client/gonzalo.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Gonzalo Berástegui",
      designation: "Software Developer"
    },
    {
      profile: "assets/images/client/daniel.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Daniel Huarte",
      designation: "Software Developer"
    },
    {
      profile: "assets/images/client/enrique.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Enrique Lafraya",
      designation: "Software Developer"
    },
    {
      profile: "assets/images/client/javier.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Javier Gracia",
      designation: "Software Developer"
    },
    {
      profile: "assets/images/client/ariel.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ariel Conde",
      designation: "UX"
    },
    {
      profile: "assets/images/client/alejandro.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Alejandro Lecumberri",
      designation: "Project coordinator"
    }
  ];

/**
 * Datos de Servicios
 */
servicesData = [
  {
    icon: "uil uil-calendar-alt h2 text-primary",
    title: "Compañero durante todo ciclo de vida",
    description: "Nos enfocamos en ser un compañero durante todo el ciclo de vida para su negocio. Podemos ayudar en la fase inicial con el desarrollo del ecosistema, así como durante la operación, proporcionando soporte y mantenimiento para todo el software."
  },
  {
    icon: "uil uil-clock h2 text-primary",
    title: "Eficiente en costes",
    description: "Utilizar una base reutilizable evita gastar recursos en piezas de software ya hechas. Tener a nuestro equipo proporcionando mantenimiento a una plataforma común significa que su costo operativo se reducirá en un 70%."
  },
  {
    icon: "uil uil-airplay h2 text-primary",
    title: "Rápido en llegar al mercado",
    description: "Inicie su viaje desde una aplicación web personalizable lista para usar, aplicación móvil, base de datos, backend y mucho más. Evite reinventar la rueda, Zero tiene los bloques de construcción más utilizados necesarios para un ecosistema de software completo y personalizable para su IoT."
  },
  {
    icon: "uil uil-clock h2 text-primary",
    title: "Personalizable para ajustarse a sus requisitos",
    description: "La arquitectura de la solución permite construir software específico sobre la plataforma base de manera fácil, lo que le permite maximizar el valor utilizable de su IoT."
  },
  {
    icon: "uil uil-clock h2 text-primary",
    title: "Posea sus datos",
    description: "Nuestra solución se puede implementar como y donde quiera, lo que significa que podría tenerla funcionando en sus propios servidores si así lo desea, teniendo control sobre los datos generados."
  },
  {
    icon: "uil uil-clock h2 text-primary",
    title: "Política de no permanencia",
    description: "La posibilidad de tener Zero funcionando en sus servidores, con un modo de licencia de por vida y propiedad sobre el software desarrollado para usted, equivale a tener la independencia para decidir sobre su futuro, sin restricciones debido a ataduras."
  }
];

/**
 * Datos de Precios
 */
pricingData = [
  {
    title: "Básico",
    price: 100,
    list: [
      "Tarifa de 50€/h por horas de desarrollo",
      "Soporte por sistema de Tickets",
      "Tiempo de soporte en base a volumen de solicitudes abiertas",
      "10 GB de almacenamiento de datos incluidos + 0.12€/GB por mes",
      "Visualización de 100 puntos de datos en la misma solicitud",
      "Versión en la nube",
      "Web",
      "Marca blanca parcial. etiqueta de'Potenciado por zeroplatform'",
      "Máximo de 10 reglas por usuario",
      "Tipo de reglas básicas incluidas"
    ],
    btn: "Solicitar reunión"
  },
  {
    title: "Profesional",
    price: 1000,
    list:  [
      "Tarifa de 50€/h por horas de desarrollo",
      "Soporte por sistema de Tickets + soporte por correo electrónico + gestor de cuenta dedicado",
      "Soporte de 8x5",
      "Tiempo máximo de respuesta de 24h",
      "Reunión de seguimiento: Mensual",
      "100 GB de almacenamiento de datos incluidos + 0.12€/GB por mes",
      "Visualización de 500 puntos de datos en la misma solicitud",
      "Versión en la nube",
      "Web + Aplicación Móvil",
      "Marca blanca completa",
      "Máximo de 50 reglas por usuario",
      "Tipo de reglas avanzadas incluidas",
      "15 horas de desarrollo de software incluidas"
    ],
    btn: "Solicitar reunión"
  },
  {
    title: "Cloud Empresarial",
    price: 4000,
    list:  [
      "Tarifa de 50€/h por horas de desarrollo",
      "soporte por sistema de Tickets + soporte por correo electrónico + soporte por teléfono + Gerente de cuenta dedicado",
      "Soporte de 24x7",
      "Respuesta instantánea",
      "Reunión de seguimiento: Semanal",
      "1000 GB de almacenamiento de datos incluidos + 0.12€/GB por mes",
      "Visualización de 10000 puntos de datos en la misma solicitud",
      "Versión en la nube",
      "Web + Aplicación Móvil",
      "Marca blanca completa",
      "Máximo de 500 reglas por usuario",
      "Tipo de reglas personalizadas incluidas",
      "150 horas de desarrollo de software incluidas"
    ],
    btn: "Solicitar reunión"
  },
  {
    title: "Server local Enterprise",
    price: 4000,
    list:  [
      "Tarifa de 50€/h por horas de desarrollo",
      "Soporte por sistema de Tickets + soporte por correo electrónico + soporte por teléfono + Gerente de cuenta dedicado",
      "Soporte de 24x7",
      "Respuesta instantánea",
      "Reunión de seguimiento: Semanal",
      "GB de almacenamiento de datos ilimitados incluidos",
      "Visualización ilimitada de puntos de datos en la misma solicitud",
      "Versión de servidor en local",
      "Opción de actualizar a licencia vitalicia. Contáctenos para más información",
      "Web + Aplicación Móvil",
      "Etiqueta blanca completa",
      "Máximo de reglas ilimitadas por usuario",
      "Tipo de reglas personalizadas incluidas",
      "150 horas de desarrollo de software incluidas"
    ],
    btn: "Solicitar reunión"
  }
];


  ngOnInit(): void {
  }

  /**
   * Open modal for show the video
   * @param content content of modal
   */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
